<template>
  <div class="card card-custom gutter-b" v-bind:class="classes">
    <div class="card-header" v-bind:class="headClass" v-if="hasTitleSlot || title">
      <div class="card-title">
        <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
        </b-button-toolbar>
        <slot name="title" v-if="hasTitleSlot"></slot>
        <h3 class="card-label" v-if="!hasTitleSlot">
          {{ title }}
        </h3>
      </div>
      <div class="card-toolbar">
        <slot name="toolbar"></slot>
      </div>
    </div>
    <div
      class="card-body-shmail"
      v-bind:class="{
        bodyClass,
        'body-fit': bodyFit,
        'body-fluid': bodyFluid,
        'card-body': !isDashboard,
      }"
    >
      <slot name="body"></slot>
    </div>
    <div class="card-footer" v-if="hasFootSlot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "KTCard",
  // data() {
  //   return {
  //     modalBool: this.modal,
  //   }
  // },
  props: [
    "title",
    "headSize",
    "fluidHeight",
    "fluidHalfHeight",
    "headOverlay",
    "cardClass",
    "headClass",
    "bodyClass",
    "bodyFit",
    "bodyFluid",
    "example",
  ],
  components: {},
  computed: {
    classes() {
      const cls = {
        "example example-compact": this.example,
        "height-fluid": this.fluidHeight,
        "height-fluid-half": this.fluidHalfHeight,
        "head-overlay": this.headOverlay,
      };

      cls[this.headSizeClass] = this.headSizeClass;

      // append extra classes
      if (this.cardClass) {
        cls[this.cardClass] = true;
      }

      return cls;
    },
    hasTitleSlot() {
      return !!this.$slots["title"];
    },
    hasFootSlot() {
      return !!this.$slots["foot"];
    },
    headSizeClass() {
      if (this.headSize) {
        return `head-${this.headSize}`;
      }
      return false;
    },
    isDashboard() {
      return this.$route.path.split("/")[1] === "dashboard";
    },
  },
};
</script>

<style scoped>
.mail-lists .card.card-custom > .card-header {
  min-height: 55px;
}

.mail-lists .card-body-shmail {
  /* min-height: 500px; */
}
</style>
